




































































































import {Component, Vue} from "vue-property-decorator";
import PortalInput from "@/components/common/PortalInput.vue";
import {IndividualContractorDTO} from "@/components/payroll/business/employments/contractors/Contractor";
import {namespace} from "vuex-class";
import IndividualContractorService
	from "@/components/payroll/business/employments/contractors/IndividualContractorService";
import PortalDate from "@/components/common/PortalDate.vue";
import RouteNames from "@/router/RouteNames";
import {WorkspaceType, WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import TerminationModal from "@/components/payroll/business/employments/contractors/TerminationModal.vue";
import TerminationPayloadDTO from "@/components/payroll/business/employments/contractors/TerminationPayloadDTO";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {ifValid} from "@/utils/ComponentUtils";
import PortalSelect from "@/components/common/PortalSelect.vue";
import BranchDto from "@/components/profile/branches/BranchDtos";
import CompanyDTO from "@/dto/company/CompanyDTO";
import SelectOption from "@/components/common/SelectOption";

const AppModule = namespace("App");

@Component({
	computed: {
		SelectOption() {
			return SelectOption
		},
		WorkspaceTypePathElement() {
			return WorkspaceTypePathElement
		},
		WorkspaceType() {
			return WorkspaceType
		}
	},
	methods: {
		RouteNames() {
			return RouteNames
		}
	},
	components: {PortalSelect, PortalDate, PortalInput}
})
export default class IndividualContractor extends Vue {

	private message: string | null = null;

	private branches: Array<BranchDto> = [];


	private successful = false;

	private contractorDetails = new IndividualContractorDTO();

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	public mounted(): void {
		this.loadContractor();
	}

	private loadContractor() {
		this.startLoading();
		IndividualContractorService.getContractorById(Number.parseInt(this.$route.params.contractorId, 10)).then(
			res => {
				this.contractorDetails = res.data;
				this.assembleBranches(this.contractorDetails.employer!)
				this.stopLoading()
			},
			err => this.processError(err)
		)
	}

	private openTerminateModal():void {
		this.$modal.show(
			TerminationModal,
			{
				terminateId: this.contractorDetails.id,
				applyTerminate: (payload: TerminationPayloadDTO) => this.terminate(payload)
			},
			{
				height: "auto"
			}
		)
	}

	private terminate(payload: TerminationPayloadDTO): void {
		this.startLoading();
		IndividualContractorService.terminateContractor(payload).then(
			() => {
				this.successful = true;
				this.message = "The contractor's contract has been terminated"
				this.$router.push({name: RouteNames.HR_EMPLOYEES, params: {companyId: String(this.contractorDetails.employer?.id)}})
				this.stopLoading()
			},
			err => this.processError(err)
		)
	}

	private assembleBranches(company: CompanyDTO) {
		if (company.headquarter) {
			this.branches.push(company.headquarter);
		}
		this.branches.push(...company.branches)
	}

	private deleteContractor(): void {
		this.startLoading()
		IndividualContractorService.deleteContractor(this.contractorDetails.id as number).then(
			() => {
				this.stopLoading()
				this.$router.push({name: RouteNames.HR_CONTRACTORS, params: {companyId: String(this.contractorDetails.employer?.id)}})
			},
			err => this.processError(err)
		)
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}

	private update(): void {
		ifValid(this, () => {
			this.startLoading()
			IndividualContractorService.updateContractor(this.contractorDetails).then(
				() => {
					this.successful = true;
					this.message = "The contractor's data has been updated"
					this.stopLoading()
				},
				err => this.processError(err)
			)
		})
	}
}

